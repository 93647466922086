import * as SubframeCore from '@subframe/core';
import React, { useState } from 'react';
import { Button, Dialog, IconButton } from 'subframe/index';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import { updateCluster } from 'api/frontend';
import * as Sentry from '@sentry/browser';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

function DeactivateClusterConfirmationDialog({
  clusterId,
  open,
  isExampleCluster,
  onClose,
}: {
  clusterId: string;
  open: boolean;
  isExampleCluster: boolean;
  onClose: () => void;
}) {
  const { account } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const troubleshootPageRemoveCluster = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector?problem=uninstall-chkk-operator',
  );
  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'deactivating this cluster',
  );

  const confirmDeactivate = async () => {
    setLoading(true);
    logEvent('deactivate-cluster', { clusterId });
    try {
      const headers = {
        Authorization: `Bearer ${account?.token}`,
      };
      await updateCluster(
        clusterId,
        {
          status: 'deactivated',
        },
        { headers },
      );
      onClose();
      enqueueSnackbar('Cluster deactivated successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      setLoading(false);
    } catch (error) {
      onClose();
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(error);
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content
        className="h-auto w-96 flex-none"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if ((event.target as HTMLAnchorElement).matches('a')) {
            // redirect to the link
            window.open((event.target as HTMLAnchorElement).href, '_blank');
          }
        }}
      >
        <div className="flex w-full flex-col items-start gap-6 p-6">
          <div className="flex w-full items-center justify-between">
            <SubframeCore.Icon
              className="hidden text-body font-body text-default-font"
              name="FeatherTrash2"
            />
            <span className="text-subheader font-subheader text-default-font">
              Deactivate the cluster?
            </span>
            <IconButton size="medium" icon="FeatherX" onClick={onClose} />
          </div>
          <div className="flex w-full flex-col items-start gap-4">
            <span className="text-body font-body text-default-font">
              {isExampleCluster
                ? `Are you sure you want to deactivate this example cluster?`
                : `Are you sure you don't want Chkk to scan this cluster for
              Operational Risks?`}
            </span>
            {isExampleCluster && (
              <span className="text-body font-body text-default-font">
                This action will remove example data from Clusters view. You can
                activate this cluster again from Settings.
              </span>
            )}
            {!isExampleCluster && (
              <>
                <span className="text-body font-body text-default-font">
                  Deactivation will disconnect Chkk&#39;s connection with your
                  cluster. After deactivation, Chkk will not scan this cluster
                  for Operational Risks, will not catalog the cluster’s control
                  plane and add-ons, and will not provide Upgrade Plans.
                </span>
                <span className="text-body font-body text-default-font">
                  You will need to activate this cluster from Settings to
                  reconnect it to Chkk.
                </span>
                <span className="text-body font-body text-default-font">
                  If you want to remove this Cluster from Chkk, See the{' '}
                  <a href={troubleshootPageRemoveCluster}>
                    Troubleshooting page
                  </a>{' '}
                  for instructions.
                </span>
              </>
            )}
          </div>
          <div className="flex w-full items-end justify-end gap-4">
            <Button
              variant="neutral-tertiary"
              size="large"
              icon="FeatherX"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="destructive-primary"
              size="large"
              icon="FeatherTrash"
              loading={loading}
              disabled={loading}
              onClick={confirmDeactivate}
            >
              Deactivate
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}

export default DeactivateClusterConfirmationDialog;
